import {useCallback, useEffect, useState} from "react";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Skeleton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {projectService} from "src/services/project/project";
import {ProjectAssignment} from "src/entities/project/project-assignment";
import {paths} from "src/pages/paths";
import {useRouter} from "src/hooks/use-router";
import {Summary} from "src/pages/dashboard/open-surveys/summary";
import {useAuth} from "src/hooks/use-auth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import {Link} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const OpenSurveys = ({projectAssignment}) => {
  const {t, i18n} = useTranslation();
  const [soonProjectAssignments, setSoonProjectAssignments] = useState(null);
  const [activeProjectAssignments, setActiveProjectAssignments] = useState(null);
  const router = useRouter();
  const theme = useTheme();
  const auth = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [copyTooltip, setCopyTooltip] = useState("Kopyalamak için tıkla!");
  const [open, setOpen] = useState(false);
  const [pendingAssignment, setPendingAssignment] = useState(null);

  useEffect(() => {
    projectService.listProjectAssignments("soon", 1).then(response => {
      const projects = response.results.map(res => new ProjectAssignment(res));
      setSoonProjectAssignments(projects);
    });
    projectService.listProjectAssignments("active", 1).then(response => {
      const projects = response.results.map(res => new ProjectAssignment(res));
      setActiveProjectAssignments(projects);
    });
  }, []);

  const handleConfirm = () => {
    setOpen(false);

    if (pendingAssignment) {
      handleStartButton(pendingAssignment);
      setPendingAssignment(null);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setPendingAssignment(null);
  };

  const handleStartButton = useCallback(
    async projectAssignment => {
      if (!pendingAssignment) {
        setOpen(true);
        setPendingAssignment(projectAssignment);
        return;
      }

      if (await auth.isTokenValid()) {
        if (projectAssignment.source_url === null) {
          router.push(paths.survey.replace(":id", projectAssignment.projectId));
        } else {
          let urlObj = new URL(projectAssignment.source_url, window.location.origin); // Ensure URL is properly parsed
          urlObj.searchParams.set("token", auth.user.reference_id);
          setTimeout(() => {
            window.open(urlObj.toString(), "_blank", "noopener,noreferrer");
          }, 100);
        }
      } else {
        router.push(paths.auth.login);
      }
    },
    [pendingAssignment],
  );

  const handleCopyReferenceId = useCallback(() => {
    if (auth.user?.reference_id) {
      const referenceId = auth.user?.reference_id;
      navigator.clipboard.writeText(
        `${window.location.origin}${paths.auth.register}?reference_id=${referenceId}`,
      );
      setCopyTooltip("Kopyalandı!");
      setTimeout(() => {
        setCopyTooltip("Kopyalamak için tıkla!");
      }, 2000);
    }
  }, [auth.user]);

  const LoadingSkeleton = () => (
    <>
      {[1, 2].map(item => (
        <Card key={item} sx={{width: isMobile ? "100%" : "24%", minHeight: "150px"}}>
          <CardContent>
            <Skeleton variant="text" width="70%" height={30} />
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="rounded" width={100} sx={{mt: 1}} height={40} />
          </CardContent>
        </Card>
      ))}
    </>
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{py: 4, mb: 8}}>
        {/* Reference ID Copy Button */}
        {/*<Box display="flex" justifyContent="center" sx={{mb: 2}}>*/}
        {/*  <Tooltip title={copyTooltip}>*/}
        {/*    <Button*/}
        {/*      startIcon={<ContentCopyIcon />}*/}
        {/*      onClick={handleCopyReferenceId}*/}
        {/*      size="small"*/}
        {/*    >*/}
        {/*      24-28 Mart arası 4 arkadaşını davet et sen 5 o 5 kazansın.*/}
        {/*    </Button>*/}
        {/*  </Tooltip>*/}
        {/*</Box>*/}

        {/* Summary Boxes */}
        <Summary />

        {/* Open Surveys Section */}
        <Typography variant="h5" sx={{mb: 2}}>
          {t("openSurveys")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "left",
            gap: 2,
            mb: 4,
          }}
        >
          {/* Survey Cards */}
          {soonProjectAssignments === null || activeProjectAssignments === null ? (
            <LoadingSkeleton />
          ) : (
            <>
              {soonProjectAssignments.map(projectAssignment => (
                <Card
                  key={projectAssignment.id}
                  sx={{width: isMobile ? "100%" : "24%", minHeight: "150px"}}
                >
                  <CardContent>
                    <Typography variant="h6">{projectAssignment.topic}</Typography>
                    <Typography variant="body2">
                      {`${t("surveyFeature.duration")}: ${projectAssignment.duration} ${t("minute")}`}
                    </Typography>
                    <Typography variant="body2">
                      {t("surveyFeature.rewardPoint")} {projectAssignment.reward}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        mt: 2,
                      }}
                      disabled={true}
                    >
                      {t("ComingSoon")}
                    </Button>
                  </CardContent>
                </Card>
              ))}
              {activeProjectAssignments.map(projectAssignment => (
                <Card
                  key={projectAssignment.id}
                  sx={{width: isMobile ? "100%" : "24%", minHeight: "150px"}}
                >
                  <CardContent>
                    <Typography variant="h6">{projectAssignment.topic}</Typography>
                    <Typography variant="body2">
                      {`${t("surveyFeature.duration")}: ${projectAssignment.duration} ${t("minute")}`}
                    </Typography>
                    <Typography variant="body2">
                      {t("surveyFeature.rewardPoint")} {projectAssignment.reward}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        mt: 2,
                      }}
                      onClick={_ => handleStartButton(projectAssignment)}
                    >
                      {t("startButton")}
                    </Button>
                    {/* Onay Diyaloğu */}
                    <Dialog open={open} onClose={() => setOpen(false)}>
                      <DialogTitle sx={{textAlign: "center"}}>Bilgilendirme</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Anket, dikkat soruları içermektedir (Aşağıdakilerden aslanı
                          seçiniz gibi). Ödül kazanabilmeniz için bu soruların doğru
                          cevaplanması gerekmektedir. Lütfen soruları dikkatle okuyunuz.
                        </DialogContentText>
                        <DialogContentText sx={{mt: 2}}>
                          Ankete başlamak için anket içeriği{" "}
                          <a
                            href="/documents/survey/Galata%20Anket%20-%20Katılımcı%20Anket%20İçeriği%20Aydınlatma%20Metni.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Aydınlatma
                          </a>{" "}
                          ve{" "}
                          <a
                            href="/documents/survey/Galata%20Anket%20-%20Katılımcı%20Anket%20İçeriği%20Açık%20Rıza%20Metni.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Açık Rıza
                          </a>{" "}
                          Metinlerini kabul etmeniz gerekmektedir.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCancel}>İptal</Button>
                        <Button
                          sx={{
                            bgcolor: "#ad1445;",
                            color: "white",
                            "&:hover": {
                              bgcolor: "#e21a5a;",
                            },
                          }}
                          onClick={handleConfirm}
                        >
                          Okudum, kabul ediyorum
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
          <Box
            sx={{
              bgcolor: "background.default",
              textAlign: "center",
            }}
          >
            <Typography variant="caption" color="textSecondary">
              Genel bilgilendirme: Farklı zamanlarda farklı katılımcılara farklı anketler
              iletilmektedir. Birden fazla hesap yönettiği belirlenen ve/veya birçok
              ankette soruları dikkatle okumadığı belirlenen kişilere anket gönderilmez.
              Size gönderilmeyen çalışmalara katılmaya çalışmanız size puan kazandırmaz ve
              daha fazla anket almanıza engel olur. Sorularınız için{" "}
              <Link href="mailto:anket@galataanket.com">anket@galataanket.com</Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
